import { useMemo } from "react"
import useDatabase from "./useDatabase"

function useId(id) {

    const database = useDatabase();
    const entity = useMemo(
        () => database.noun.get(id) ||
                database.pronoun.get(id) ||
                database.question.get(id) ||
                database.numeral.get(id) ||
                database.prepostposition.get(id),
        [ id, database ]
    );

    return entity;
}

export default useId