
/*import questions from "../database/armenian/questions.json"
import pronouns from "../database/armenian/pronouns.json"
import declensions from "../database/armenian/declensions.json"
import numerals from "../database/armenian/numerals.json"
import * as nouns from "../database/armenian/nouns"
import * as cases from "../database/armenian/cases"
import * as articles from "../database/armenian/articles"*/

import Case from "types/Case"
import Noun from "types/Noun"
import Pronoun from "types/Pronoun"
import Numeral from "types/Numeral"
import Question from "types/Question"
import Article from "types/Article"
import Prepostposition from "types/Prepostposition"

import supabase from "./supabase"


async function downloadTable(name, order) {

    let promise = supabase.from(name).select();
    if (order) {
        promise = promise.order(order.by, order.how);
    }
    const { data, error } = await promise;

    return data;
}

async function downloadAllTables() {
    const promises = [
        downloadTable("case", { by: "position", how: { ascending: true }}),
        downloadTable("declension"),

        downloadTable("prepostposition"),
        downloadTable("prepostposition_case"),
        downloadTable("prepostposition_case_example"),

        downloadTable("noun"),
        downloadTable("noun_case"),
        downloadTable("noun_case_example"),

        downloadTable("pronoun"),
        downloadTable("pronoun_case"),
        downloadTable("pronoun_case_example"),

        downloadTable("numeral"),
        downloadTable("numeral_case"),
        downloadTable("numeral_case_example"),

        downloadTable("question"),
        downloadTable("question_case"),
        downloadTable("question_case_example"),

        downloadTable("case_noun_group"),
        downloadTable("case_noun_group_noun"),
        downloadTable("case_question"),

        downloadTable("article", { by: "position", how: { ascending: true }})
    ];
    
    return await Promise.all(promises);
}

const caseOrder = [
    "nominative",
    "possessive",
    "dative",
    "ablative",
    "locative"
]

function sortCases(a, b) {
    const inx1 = caseOrder.indexOf(a.id);
    const inx2 = caseOrder.indexOf(b.id);
    return inx1 === inx2 ? 0 : 
            inx1 < inx2 ? -1 : 1;
}


let _loadingPromise;

function sortByField(field) {
    return (a, b) => {
        const v1 = typeof field === "function" ? field(a) : a[field];
        const v2 = typeof field === "function" ? field(b) : b[field];
        return v1 === v2 ? 0 :
                v1 < v2 ? -1 : 1;
    }
}

function splitArticleKey(key) {
    key = key.replace(/[A-Z]/g, (match, index) => {
        return `${ index > 0 ? "-" : "" }${ match.toLowerCase() }`;
    });

    const parts = key.split("-");
    const language = parts[0];
    const id = parts.slice(1).join("/");
    return { id, language }
}

class Table {
    index = {};
    rows = [];
    entities = {};
    entityClass = null;

    constructor(entityClass, rows = []) {
        this.entityClass = entityClass;
        this.setRows(rows);
    }

    setRows(rows) {
        this.rows = [ ...rows ];
        this.rows.forEach(row => {
            this.index[row.id] = row;
        });
    }

    addRows(rows) {
        rows.forEach(row => this.addRow(row));
    }
    
    addRow(row) {
        if (!this.index[row.id]) {
            this.rows.push(row);
            this.index[row.id] = row;
        }
    }

    sort(fn) {
        this.rows = this.rows.sort(fn);
    }

    get(id) {
        const Cls = this.entityClass;
        const row = this.index[id];
        if (Cls) {
            if (!this.entities[id]) {
                if (!row) {
                    //throw new Error(`${ Cls.name } with id: ${ id } not found`);
                    return null;
                }
                this.entities[id] = new Cls(row);
            }
            return this.entities[id];
        }
        return row ? { ...row } : null;
    }

    query(query = null, sort = null) {
        let data;

        if (query === null) {
            data = [ ...this.rows ];
        }
        else if (typeof query === "function") {
            data = this.rows.filter(row => query(row));
        }
        else if (Array.isArray(query)) {
            data = this.rows.filter(row => query.indexOf(row.id) !== -1);
        }

        if (typeof sort === "string") {
            data = data.sort(sortByField(sort));
        }
        else if (typeof sort === "function") {
            data = data.sort(sort);
        }
        else if (sort) {
            data = data.sort(sortByField(sort.field));
            if (sort.direction === "desc") {
                data = data.reverse();
            }
        }

        return this.entityClass ? 
                data.map(row => this.get(row.id)) :
                data;
    }

    count() {
        return this.rows.length;
    }
}

class Database {

    _loaded = false

    question = null
    pronoun = null
    numeral = null
    declension = null
    noun = null
    case = null
    article = null
    prepostposition = null

    constructor() {
        
        this.noun = new Table(Noun);
        this.case = new Table(Case);
        this.declension = new Table();
        this.pronoun = new Table(Pronoun);
        this.question = new Table(Question);
        this.numeral = new Table(Numeral);
        this.article = new Table(Article);
        this.prepostposition = new Table(Prepostposition);
    }

    async loadRemote() {

        if (_loadingPromise) {
            return _loadingPromise;
        }

        if (this._loaded) {
            return;
        }

        const tablePromise = downloadAllTables();

        

        _loadingPromise = new Promise(async (resolve) => {
            const [
                cases,
                declension,
                prepostposition,
                prepostposition_case,
                prepostposition_case_example,
                noun,
                noun_case,
                noun_case_example,
                pronoun,
                pronoun_case,
                pronoun_case_example,
                numeral,
                numeral_case,
                numeral_case_example,
                question,
                question_case,
                question_case_example,
                case_noun_group,
                case_noun_group_noun,
                case_question,
                article
            ] = await tablePromise;
            
            this.declension.addRows(declension);

            this.article.addRows(article.map(a => {
                return {
                    id: `/case/${ a.case_id }/${ a.id }`,
                    language: a.language,
                    text: a.text,
                    title: a.title
                }
            }))

            this.prepostposition.addRows(
                prepostposition.map(n => {
                    const prepostposition = { type: "prepostposition", ...n };
                    prepostposition.cases = prepostposition_case
                        .filter(nc => nc.prepostposition_id === n.id)
                        .map(nc => {
                            return {
                                case: nc.case_id,
                                single: nc.single,
                                comment: nc.comment,
                                examples: prepostposition_case_example
                                            .filter(nce => nce.prepostposition_id === n.id && 
                                                            nce.case_id === nc.case_id)
                                            .map(nce => {
                                                return {
                                                    pposition: nce.prepostposition_id,
                                                    ...nce.example
                                                }
                                            })
                            }
                        })
                    return prepostposition;
                })
                .filter(pp => {
                    return pp.cases.length > 0;
                })
            );
    
            this.noun.addRows(
                noun.map(n => {
                    const noun = { type: "noun", ...n };
                    noun.cases = noun_case
                        .filter(nc => nc.noun_id === n.id)
                        .map(nc => {
                            return {
                                case: nc.case_id,
                                declension: nc.declension_id || null,
                                single: nc.single,
                                plural: nc.plural,
                                comment: nc.comment,
                                examples: noun_case_example
                                            .filter(nce => nce.noun_id === n.id && 
                                                            nce.case_id === nc.case_id)
                                            .map(nce => {
                                                return {
                                                    pposition: nce.prepostposition_id,
                                                    ...nce.example
                                                }
                                            })
                            }
                        })
                    return noun;
            }));
    
            this.pronoun.addRows(
                pronoun.map(pn => {
                    const pronoun = { type: "pronoun", ...pn };
                    pronoun.cases = pronoun_case
                        .filter(pnc => pnc.pronoun_id === pn.id)
                        .map(pnc => {
                            return {
                                case: pnc.case_id,
                                single: pnc.single,
                                comment: pnc.comment,
                                examples: pronoun_case_example
                                            .filter(pnce => pnce.pronoun_id === pn.id && 
                                                            pnce.case_id === pnc.case_id)
                                            .map(pnce => {
                                                return {
                                                    pposition: pnce.prepostposition_id,
                                                    ...pnce.example
                                                }
                                            })
                            }
                        })
                    return pronoun;
            }));
    
            this.numeral.addRows(
                numeral.map(n => {
                    const numeral = { type: "numeral", ...n };
                    numeral.cases = numeral_case
                        .filter(nc => nc.numeral_id === n.id)
                        .map(nc => {
                            return {
                                case: nc.case_id,
                                single: nc.single,
                                plural: nc.plural,
                                comment: nc.comment,
                                examples: numeral_case_example
                                            .filter(nce => nce.numeral_id === n.id && 
                                                            nce.case_id === nc.case_id)
                                            .map(nce => {
                                                return {
                                                    ...nce.example
                                                }
                                            })
                            }
                        })
                    return numeral;
            }));
    
            this.question.addRows(
                question.map(q => {
                    const question = { type: "question", ...q };
                    question.cases = question_case
                        .filter(qc => qc.question_id === q.id)
                        .map(qc => {
                            return {
                                case: qc.case_id,
                                single: qc.single,
                                plural: qc.plural,
                                comment: qc.comment,
                                examples: question_case_example
                                            .filter(qce => qce.question_id === q.id && 
                                                            qce.case_id === qc.case_id)
                                            .map(qce => {
                                                return {
                                                    pposition: qce.prepostposition_id,
                                                    ...qce.example
                                                }
                                            })
                            }
                        })
                    return question;
            }));
    
            this.case.addRows(
                cases.map(c => {
                    const cs = { ...c };
    
                    cs.articles = article
                                    .filter(a => a.case_id === c.id)
                                    .map(a => {
                                        return `/case/${ a.case_id }/${ a.id }`
                                        /*return {
                                            //case: a.case_id,
                                            id: `/case/${ a.case_id }/${ a.id }`,
                                            language: a.language,
                                            text: a.text,
                                            title: a.title
                                        }*/
                                    });
                        
                    const questionTypes = [];
                    cs.questions = case_question
                                        .filter(cq => cq.case_id === c.id)
                                        .map(cq => {
                                            if (cq.type && questionTypes.indexOf(cq.type) === -1) {
                                                questionTypes.push(cq.type);
                                            }
                                            return {
                                                type: cq.type,
                                                pposition: cq.prepostposition_id,
                                                question: cq.question,
                                                comment: cq.comment
                                            }
                                        });
                    cs.questionGroups = questionTypes.map(type => {
                        return {
                            type,
                            name: {
                                russian: type === "noun" ? "Для существительных" : "Для местоимений"
                            }
                        }
                    });
    
                    const declensions = noun_case
                                            .filter(nc => nc.case_id === c.id)
                                            .filter(nc => !!nc.declension_id)
                                            .map(nc => nc.declension_id)
                                            .filter((d, inx, self) => self.indexOf(d) === inx);
    
                    cs.declensions = declensions.map(d => {
                        const groups = case_noun_group
                                        .filter(cng => cng.case_id === c.id && cng.declension_id === d)
                                        .map(cng => {
                                            return {
                                                name: cng.name,
                                                description: cng.description,
                                                comment: cng.comment,
                                                words: case_noun_group_noun
                                                        .filter(cngn => cngn.case_noun_group_id === cng.id)
                                                        .map(cngn => cngn.noun_id)
                                            }
                                        });
                        if (groups.length > 0) {
                            return {
                                declension: d,
                                groups
                            }
                        }
                        return d;
                    });
    
                    cs.groups = case_noun_group
                                    .filter(cng => cng.case_id === c.id && 
                                                    cng.declension_id === null)
                                    .map(cng => {
                                        return {
                                            name: cng.name,
                                            description: cng.description,
                                            comment: cng.comment,
                                            words: case_noun_group_noun
                                                    .filter(cngn => cngn.case_noun_group_id === cng.id)
                                                    .map(cngn => cngn.noun_id)
                                        }
                                    });
    
                    return cs;
                })
            );
            resolve();
        })
        

        await _loadingPromise;

        this._loaded = true;
        _loadingPromise = null;
    }

    async loadLocal() {

        if (_loadingPromise) {
            return _loadingPromise;
        }

        if (this._loaded) {
            return;
        }
        
        const index = await fetch("/database.json", {cache: "no-store"}).then(resp => resp.json());
        const promises = [];
        
        index.forEach(file => {
            const ext = file.split(".").pop();
            promises.push(
                fetch(`/database/${ file }`, {cache: "no-store"})
                .then(resp => {
                    if (ext === "json") {
                        return resp.json();
                    }
                    else {
                        return resp.text();
                    }
                })
                .then(data => {
                    const parts = file.replace("." + ext, "").split("/");
                    const table = parts.shift();
                    if (table === "article") {
                        const language = parts.shift();
                        const id = parts.join("/");
                        this.article.addRow({
                            id, 
                            language,
                            text: data
                        })
                    }
                    else if (parts.length === 0) {
                        this[table].addRows(data);
                    }
                    else {
                        this[table].addRow(data);
                    }
                })
            )
        })


        _loadingPromise = new Promise(async (resolve) => {
            await Promise.all(promises);
            this.case.sort(sortCases);
            resolve();
        })

        await _loadingPromise;

        this._loaded = true;
        _loadingPromise = null;

    }
}

export default Database