import { useContext, useMemo } from "react"

import CaseContext from "./cases/Context"
import Text from "./Text"
import useGet from "hooks/useGet"
import useContextKey from "hooks/useContextKey"
import AppContext from "context/AppContext"

function OneForm({ form, pfx = "" }) {
    const language = useContextKey("language", AppContext);
    return (
        <p className="word-form">
            { pfx && <span className="pfx">{ pfx }</span> }
            <span className="armenian"><Text t={ form.armenian }/></span>
            { form.informal && 
                <span className="informal">
                    <i>разг.</i>
                    <Text t={ form.informal }/>
                </span> }
            { form.transcription && 
                <span className="transcription"><Text t={ form.transcription }/></span> }
            <span className="translation"><Text t={ form }/></span>
            { (form.comment && form.comment[language]) && 
                <span className="comment">(<Text t={ form.comment }/>)</span>}
        </p>
    )
}

function WordInfo({ word }) {
    const lcase = useContext(CaseContext);
    const nominativeCase = useGet("case", "nominative");
    const language = useContextKey("language", AppContext);

    const nominative = useMemo(
        () => word.cases.find(c => c.case === "nominative"),
        [ word ]
    );

    const caseForm = useMemo(
        () => lcase.id !== "nominative" ? 
                word.cases.find(c => c.case === lcase.id) :
                null,
        [ word, lcase ]
    );

    return (
        <div className="word__info">

            { lcase.id !== "nominative" && <h5><Text t={ nominativeCase.name }/></h5> }
            { nominative.single ? 
                <>
                    <OneForm form={ nominative.single } pfx="ед.ч."/>
                    { (nominative.plural && nominative.plural[language]) &&  
                        <OneForm form={ nominative.plural } pfx="мн.ч."/> }
                </> :
                <OneForm form={ nominative }/> }
            
            { lcase.id !== "nominative" && 
                <>
                <h5><Text t={ lcase.name }/></h5>
                { (caseForm.single && caseForm.single[language]) ? 
                    <>
                    <OneForm form={ caseForm.single } pfx="ед.ч."/>
                    { (caseForm.plural && caseForm.plural[language]) && 
                        <OneForm form={ caseForm.plural } pfx="мн.ч."/> }
                    </> :
                    <OneForm form={ caseForm }/> }
                </> }
        </div>
    )
}

export default WordInfo