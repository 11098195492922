import { useCallback, useState } from "react"
import { useOn } from "@kuindji/observable-react"


function useKey(key, ctx) {
    const [ value, setValue ] = useState(() => ctx ? ctx.get(key) : null);

    const onChange = useCallback(
        () => ctx ? setValue(ctx.get(key)) : null,
        [ key, ctx ]
    );

    useOn(ctx, "change", onChange);

    return value;
}

export default useKey