import { useMemo } from "react";
import useTable from "./useTable"

function useQuery(tableName, query, sort) {

    const table = useTable(tableName);
    const data = useMemo(
        () => table.query(query, sort),
        [ query, sort, table ]
    );

    return data;
}

export default useQuery