import { useRef, useEffect } from "react"

function useUpdateEffect(fn, deps) {

    const callRef = useRef(0);

    useEffect(
        () => {

            if (callRef.current > 0) {
                fn();
            }

            callRef.current += 1;
        },
        // eslint-disable-next-line
        [ ...deps ]
    );
}

export default useUpdateEffect