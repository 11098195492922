
const accents = {
    russian: {
        "а́": "а",
        "é": "е",
        "и́": "и",
        "ó": "о",
        "у́": "у",
        "ы́": "ы",
        "э́": "э",
        "я́": "я",
        "ю́": "ю",
    },
    english: {
        "é": "e",
        "ú": "u",
        "í": "i",
        "ó": "o",
        "á": "a"
    }
}

function strip(text, language) {
    
    text = (text || "").toLowerCase().replaceAll("*", "");
    Object.keys(accents[language]).forEach(letter => {
        text = text.replaceAll(letter, accents[language][letter]);
    })
    return text;
}


class Word {

    id = null
    type = null
    single = {}
    plural = {}
    cases = []

    constructor(word) {
        Object.assign(this, word);
    }

    getAllForms({ language = "" }) {
        const forms = [];

        this.cases.forEach(w => {
            if (w.single) {
                forms.push(w.single.armenian);
                forms.push(w.single[language]);
            }
            if (w.plural) {
                forms.push(w.plural.armenian);
                forms.push(w.plural[language]);
            }
            if (!w.single) {
                forms.push(w.armenian);
                forms.push(w[language]);
            }
        });
    
        return forms.flat().filter(f => !!f).map(w => strip(w, language));
    }

    getExamples(caseId, filters = []) {
        let examples = this.cases.find(c => c.case === caseId)?.examples || [];
        if (filters.length === 0) {
            return examples;
        }
        return examples.filter(e => !!filters.find(f => f.matchPPosition(e)));
    }

    matchesFilter(filter) {

        const { query, pposition, language, word } = filter.getData();
        const options = { language };

        if (pposition) {
            if (this.type !== pposition.type) {
                return false;
            }

            const hasPpositionExample = !!this.cases.find(c => {
                if (!c.examples) {
                    return false;
                }
                if (!c.examples.find(e => e.pposition === pposition.pposition)) {
                    return false;
                }
                return true;
            });

            if (!hasPpositionExample) {
                return false;
            }
        }

        if (word) {
            return this.id === word;
        }

        if (!query) {
            return true;
        }

        return !!this.getAllForms(options).find(word => word.indexOf(query.toLowerCase()) !== -1);
    }
}

export default Word