import { useCallback, useMemo, useRef, useState } from "react"

import useQuery from "hooks/useQuery"

import CaseFull from "components/cases/Case"
import useFilter from "hooks/useFilter"
import Search from "components/Search"

import { useOn } from "@kuindji/observable-react"
import useSwallowEventCallback from "hooks/useSwallowEventCallback"


function Cases() {

    const globalFilter = useFilter();
    const allCases = useQuery("case");
    const scrollRef = useRef(null);

    const [ cases, setCases ] = useState(
        () => allCases.filter(c => c.matchesFilter(globalFilter)));

    const isMac = useMemo(
        () => (window.navigator.platform || "").indexOf("Mac") !== -1,
        []
    );

    const onFilterUpdate = useCallback(
        () => setCases(allCases.filter(c => c.matchesFilter(globalFilter))),
        [ allCases, globalFilter ]
    );

    const onScrollLeft = useSwallowEventCallback(
        () => {
            const w = document.documentElement.offsetWidth;
            scrollRef.current.scrollBy({ 
                left: -(w/4),
                behavior: 'smooth' 
            });
        },
        []
    );

    const onScrollRight = useSwallowEventCallback(
        () => {
            const w = document.documentElement.offsetWidth;
            scrollRef.current.scrollBy({ 
                left: (w/4),
                behavior: 'smooth' 
            });
        },
        []
    );

    useOn(globalFilter, "change", onFilterUpdate);

    return (
        <>
        <Search/>
        { !isMac && 
            <div className="full-case-list-scroll-actions">
                <a href="/#" onClick={ onScrollLeft }>←</a>
                <a href="/#" onClick={ onScrollRight }>→</a>
            </div> }
        <div className="full-case-list-scrollable" ref={ scrollRef }>
            <div className="full-case-list">
                { cases.map(c => <CaseFull key={ c.id } id={ c.id }/>) }
            </div>
        </div>
        </>
    )
}

export default Cases