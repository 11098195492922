import { useMemo } from "react"
import useTable from "./useTable"

function useGet(tableName, id) {

    const table = useTable(tableName);
    const row = useMemo(() => table.get(id), [ id, table ]);

    return row;
}

export default useGet