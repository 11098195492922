import { useEffect, useMemo } from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"

import DatabaseContext from "context/DatabaseContext"
import AppContext from "context/AppContext"
import { GlobalFilterContext } from "context/FilterContext"

import ChaptersMenu from "components/ChaptersMenu"
import Header from "components/Header"
import Footer from "components/Footer"

import PageCases from "./pages/Cases"
import PageAlphabet from "./pages/Alphabet"

import GlobalFilter from "./lib/Filter"

import database from "database"
import Context from "lib/Context"
import Loader from "components/Loader"
import useKey from "hooks/useKey"


function MainView() {
    return (
        <BrowserRouter basename="/">
            <Header/>
            <ChaptersMenu/>
            <Routes>
                <Route path="/" element={<Navigate to="/cases" />} />
                <Route path="/cases" element={ <PageCases/> }/>
                <Route path="/alphabet" element={ <PageAlphabet/> }/>
            </Routes>
            <Footer/>
        </BrowserRouter>
    )
}


function App() {

    const app = useMemo(
        () => new Context({ language: "russian", loading: true }), 
        []
    );
    const loading = useKey("loading", app);

    const globalFilter = useMemo(
        () => new GlobalFilter({ language: app.get("language") }), 
        [ app ]
    );

    useEffect(
        () => {
            database.loadRemote().then(() => {
                app.set("loading", false)
            });  
        },
        [ app ]
    );

    return (
        <AppContext.Provider value={ app }>
        <GlobalFilterContext.Provider value={globalFilter}>
        <DatabaseContext.Provider value={database}>
            { loading ? <Loader/> : <MainView/> }
        </DatabaseContext.Provider>
        </GlobalFilterContext.Provider>
        </AppContext.Provider>
    );
}

export default App;
