
import database from "database"

class Case {

    id = null
    name = null
    description = null
    wordGroups = []
    questionGroups = []
    nouns = []
    pronouns = []
    numerals = []
    prepostpositions = []
    declensions = []
    questionWords = []
    allWords = new Set()

    constructor(lcase) {
        this.id = lcase.id;
        this.name = lcase.name;
        this.description = lcase.description;

        this._importQuestions(lcase);
        this._importWords(lcase);
        this._importDeclensions(lcase);
        this._importArticles(lcase);
    }

    _importArticles(lcase) {
        this.articles = database.article.query(lcase.articles || []);
    }

    _importWords(lcase) {
        const id = this.id;
        
        // word groups and words
        const wordsInGroups = (lcase.groups || []).map(g => g.words).flat() || [];
        this.wordGroups = (lcase.groups || [])
            .map(g => ({ ...g, words: database.noun.query(g.words) }))
            .filter(g => g.words.length > 0);

        this.pronouns = database.pronoun
            .query(w => !!w.cases.find(c => c.case === id));
        
        this.nouns = database.noun
            .query(w => {
                return wordsInGroups.indexOf(w.id) === -1 && 
                        !!w.cases.find(c => c.case === id && !c.declension);
            });
        
        this.numerals = database.numeral
            .query(w => !!w.cases.find(c => c.case === id));

        this.prepostpositions = database.prepostposition
            .query(w => !!w.cases.find(c => c.case === id));

        this.questionWords = database.question
            .query(w => !!w.cases.find(c => c.case === id));

        this.numerals.forEach(w => this.allWords.add(w));
        this.nouns.forEach(w => this.allWords.add(w));
        this.pronouns.forEach(w => this.allWords.add(w));
        this.wordGroups.forEach(g => {
            g.words.forEach(w => this.allWords.add(w));
        });
        this.questionWords.forEach(w => this.allWords.add(w));
        this.prepostpositions.forEach(w => this.allWords.add(w));
    }

    _importQuestions(lcase) {

        const questionGroups = lcase.questionGroups ? [...lcase.questionGroups] : [];
        const map = {};
        questionGroups.forEach(g => {
            g.questions = [];
            map[g.type] = g;
        });
        if (questionGroups.length === 0 && lcase.questions?.length > 0) {
            const g = {
                id: "default",
                questions: []
            };
            questionGroups.push(g);
            map["default"] = g;
        }
        lcase.questions?.forEach(q => {
            map[q.type || "default"].questions.push(q);
        });

        this.questionGroups = questionGroups.filter(qg => qg.questions.length > 0);
    }

    _importDeclensions(lcase) {

        const id = this.id;

        this.declensions = (lcase.declensions || [])
            // fetch default data from db
            .map(d => ({
                ...database.declension.get(typeof d === "string" ? d : d.declension),
                ...(typeof d === "string" ? null : d)
            }))
            // prepare word groups inside declension
            .map(declension => {

                // if case declension definition has groups defined
                if (declension.groups) {
                    const wordsInGroups = declension.groups.map(g => g.words).flat();

                    // first, prepare specific word groups
                    const groups = declension.groups.map(g => ({
                        ...g,
                        words: database.noun.query(g.words)
                                .filter(w => {
                                    return !!w.cases.find(c => c.case === id && 
                                            c.declension === declension.id);
                                })
                    }));
                    
                    // then find all the rest which are not in groups
                    const rest = database.noun
                        .query(w => {
                            return wordsInGroups.indexOf(w.id) === -1 &&
                                    !!w.cases.find(c => c.case === id && 
                                    c.declension === declension.id);
                        });
                    if (rest.length > 0) {
                        groups.unshift({ words: rest });
                    }

                    declension.groups = groups;
                }
                // otherwise create default group
                else {
                    const words = database.noun
                        .query(w => {
                            return !!w.cases.find(c => c.case === id && 
                                        c.declension === declension.id)
                        });
                    declension.groups = [{ words }];   
                }

                declension.groups = declension.groups.filter(g => g.words.length > 0)

                return declension;
            })
            .filter(declension => declension.groups.length !== 0);

        this.declensions.forEach(d => {
            d.groups.forEach(g => {
                g.words.forEach(w => this.allWords.add(w));
            })
        })
    }


    matchesFilter(filter) {

        let matches = false;
        for (const entry of this.allWords) {
            if (entry.matchesFilter(filter)) {
                matches = true;
                break;
            }
        }

        return matches;
    }


    /*_prepareWord(w) {
        word.cases = w.cases.map(c => {
            if (c.case !== lcase.id) {
                return c;
            }
            return {
                ...c, 
                examples: (c.examples || []).filter(e => this.matchPPosition(e))
            }
        })
        return word;
    }*/

    getData(filter) {

        const data = {
            id: this.id,
            name: this.name,
            description: this.description,
            pronouns: this.pronouns,
            nouns: this.nouns,
            wordGroups: this.wordGroups,
            questionGroups: this.questionGroups,
            declensions: this.declensions,
            numerals: this.numerals,
            questionWords: this.questionWords,
            articles: this.articles,
            prepostpositions: this.prepostpositions
        }

        data.nouns = data.nouns.filter(w => w.matchesFilter(filter));
        data.pronouns = data.pronouns.filter(w => w.matchesFilter(filter));
        data.numerals = data.numerals.filter(w => w.matchesFilter(filter));
        data.prepostpositions = data.prepostpositions.filter(w => w.matchesFilter(filter));
        data.questionWords = data.questionWords.filter(w => w.matchesFilter(filter));
        data.wordGroups = data.wordGroups
            .map(g => {
                const words = g.words.filter(w => w.matchesFilter(filter));
                return { ...g, words };
            })
            .filter(g => g.words.length > 0);
        data.declensions = data.declensions
            .map(d => {
                const groups = d.groups
                    .map(g => {
                        const words = g.words.filter(w => w.matchesFilter(filter));
                        return { ...g, words };
                    })
                    .filter(g => g.words.length > 0);
                return { ...d, groups };
            })
            .filter(d => d.groups.length > 0);

        return data;
    }

    isEmpty() {
        return this.pronouns.length === 0 &&
                this.nouns.length === 0 &&
                this.wordGroups.length === 0 &&
                this.declensions.length === 0 &&
                this.numerals.length === 0 &&
                this.questionWords.length === 0 &&
                this.prepostpositions.length === 0;
    }

}

export default Case