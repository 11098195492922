
import Declension from "./Declension"


function Declensions({ declensions }) {
    
    if (declensions.length === 0) {
        return null;
    }

    return (
        <div className="full-case__declensions">
            { declensions.map(d => <Declension key={ d.id } declension={ d }/> )}
        </div>
    )
}

export default Declensions