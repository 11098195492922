import Context from "./Context"

class Filter extends Context {


}

Filter.combine = (filters) => {
    const data = {};
    filters.forEach(f => Object.assign(data, f.getData()));
    return new Filter(data);
}

Filter.matchPPosition = (pposition, smth) => {
    
    if (!pposition) {
        return true;
    }
    return (smth.pposition || null) === (pposition.pposition || null);
}

export default Filter