
import Text from "components/Text"
import LinkOrNot from "components/LinkOrNot"
import { LocalFilterContext } from "context/FilterContext"
import useFilter from "hooks/useFilter"
import useFilterKey from "hooks/useFilterKey"
import useSwallowEventCallback from "hooks/useSwallowEventCallback"
import { useMemo } from "react"
import AppContext from "context/AppContext"
import useContextKey from "hooks/useContextKey"

function Question({ q }) {

    const localFilter = useFilter(LocalFilterContext);
    const pp = useFilterKey("pposition", LocalFilterContext);
    const language = useContextKey("language", AppContext);
    
    const onClick = useSwallowEventCallback(
        () => {
            if (!pp || (pp.pposition !== q.pposition || pp.type !== q.type)) {
                localFilter.set("pposition", { pposition: q.pposition || null, type: q.type });
                return;
            }
            else {
                localFilter.set("pposition", null);
            }
        },
        [ q, pp ]
    );

    const cls = useMemo(
        () => [ 
            "clickable",
            !!pp && pp.pposition === q.pposition && pp.type === q.type ? "active" : ""
        ].join(" "),
        [ pp, q ]
    );

    return (
        <li className={ cls }>
            <a href="/#" onClick={ onClick }>
                <Text t={ q.question }/>
                { (q.comment && q.comment[language]) && 
                    <span className="comment"><Text t={ q.comment }/></span> }
            </a>
        </li>
    )
}

function QuestionGroup({ group }) {

    if (group.questions.length === 0) {
        return null;
    }

    return (
        <div className="full-case__qgroup">
            { group.name && <h4><Text t={ group.name }/></h4> }
            <ul>
                { group.questions.map((q, inx) => <Question key={ inx } q={ q }/>)}
            </ul>
        </div>
    )
}

export default QuestionGroup