import { useCallback, useMemo, useState } from "react"
import { useOn } from "@kuindji/observable-react"

import Text from "components/Text"

import CaseContext from "./Context"
import { LocalFilterContext } from "context/FilterContext"
import AppContext from "context/AppContext"

import QuestionGroups from "./QuestionGroups"
import Declensions from "./Declensions"
import Words from "components/Words"
import Article from "./Article"


import Filter from "lib/Filter"
import useFilter from "hooks/useFilter"
import useGet from "hooks/useGet"
import useContextKey from "hooks/useContextKey"
import useKey from "hooks/useKey"


function CaseFull({ id }) {

    const lcase = useGet("case", id);
    const globalFilter = useFilter();
    const language = useContextKey("language", AppContext);
    const wordId = useKey("word", globalFilter);
    const localFilter = useMemo(
        () => new Filter({ language }), 
        // eslint-disable-next-line
        []
    );
    
    const [ data, setData ] = useState(
        () => lcase.getData(Filter.combine([ globalFilter, localFilter ]))
    );

    const onFilterChange = useCallback(
        () => {
            setData(lcase.getData(Filter.combine([ globalFilter, localFilter ])))
        },
        [ globalFilter, localFilter, lcase ]
    );

    useOn(localFilter, "change", onFilterChange);
    useOn(globalFilter, "change", onFilterChange);

    return (
        <CaseContext.Provider value={ lcase }>
        <LocalFilterContext.Provider value={ localFilter }>
        <div className="full-case">
            <div className="full-case__header">
                <h2><Text t={ data.name }/></h2>
                { data.description && <p className="description"><Text t={ data.description }/></p> }
            </div>
            { data.articles.length > 0 && 
                <div className="full-case__articles">
                    { data.articles.map(a => <Article key={ a.id } article={ a }/> )}
                </div> }
            { !wordId && <QuestionGroups questionGroups={ data.questionGroups }/> }
            <Words words={ data.pronouns } title="Местоимения" limit={ 10 }/>
            <Words words={ data.nouns } title="Существительные" comment="регулярные"/>
            <Declensions declensions={ data.declensions }/>
            { data.wordGroups.map((g, inx) => <Words 
                                        className="full-case__group"
                                        key={ inx } 
                                        words={ g.words } 
                                        title={ g.name }/> )}
            <Words words={ data.numerals } title="Числительные"/>
            <Words words={ data.questionWords } title="Вопросительные слова"/>
            <Words words={ data.prepostpositions } title="Послелоги" comment="у которых есть падежи"/>
            
        </div>
        </LocalFilterContext.Provider>
        </CaseContext.Provider>
    )
}

export default CaseFull