import { useContext, useMemo, useState, useRef } from "react"

import Text from "./Text"
import WordInfo from "./WordInfo"

import { ReactComponent as IconExpand } from "svg/expand.svg"

import useSwallowEventCallback from "hooks/useSwallowEventCallback"
import { GlobalFilterContext, LocalFilterContext } from "context/FilterContext"
import useFilter from "hooks/useFilter"
import useFilterKey from "hooks/useFilterKey"
import Filter from "lib/Filter"
import CaseContext from "./cases/Context"
import AppContext from "context/AppContext"
import useKey from "hooks/useKey"

function Word({ word }) {

    const app = useContext(AppContext);
    const language = useKey("language", app);
    const globalFilter = useFilter(GlobalFilterContext);
    const globalWordId = useKey("word", globalFilter);
    const lcase = useContext(CaseContext);
    const pp = useFilterKey("pposition", LocalFilterContext);
    const [ localExpanded, setLocalExpanded ] = useState(null);
    const ref = useRef(null);
    const caseId = lcase?.id;

    const expanded = useMemo(
        () => localExpanded || globalWordId === word.id,
        [ localExpanded, globalWordId, word ]
    );

    const cls = useMemo(
        () => [ "word", expanded ? `expanded` : "" ].join(" "),
        [ expanded ]
    );

    const nominative = useMemo(
        () => {
            const caseForm = word.cases.find(c => c.case === "nominative");
            return caseForm.single || caseForm;
        },
        [ word ]
    );

    const caseForm = useMemo(
        () => word.cases.find(c => c.case === (lcase?.id || "nominative")),
        [ word, lcase ]
    );

    const examples = useMemo(
        () => {
            const examples = caseForm.examples || [];
            return examples.filter(e => Filter.matchPPosition(pp, e));
        },
        [ caseForm, pp ]
    );

    const form = useMemo(
        () => caseForm.single || caseForm,
        [ caseForm ]
    );

    const hasInfo = useMemo(
        () => {
            if (lcase.id === "nominative") {
                const cform = word.cases.find(c => c.case === "nominative");
                if ((!cform.plural || !cform.plural.armenian) && examples.length === 0) {
                    return false;
                }
            }
            return true;
        },
        [ lcase, word, examples ]
    );

    const onExpandClick = useSwallowEventCallback(
        () => setLocalExpanded(prev => !prev),
        []
    );

    const onWordClick = useSwallowEventCallback(
        () => globalFilter.set("word", word.id),
        [ word, nominative, app ]
    );

    /*const onMouseOver = useCallback(
        () => {
            withCard && 
                app.trigger("dialog/word-card", word, {
                    triggerMode: "hover",
                    trigger: ref.current,
                    target: "pointer",
                    position: "r"
                })
        },
        [ withCard, ref.current, app, word ]
    );*/

    return (
        <div className={ cls } ref={ ref }>
            <div className="word__header">

                { (caseId && caseId !== "nominative") ? 
                    <a className="nominative" 
                        href="/#"
                        onClick={ onWordClick }><Text t={ nominative.armenian }/></a> : 
                    null }
                
                { caseId === "nominative" ?
                    <a href="/#" 
                        className="armenian"
                        onClick={ onWordClick }><Text t={ form.armenian }/></a> :
                    <span className="armenian"><Text t={ form.armenian }/></span> }

                { (form.transcription) && 
                    <span className="transcription"><Text t={ form.transcription }/></span> }

                <span className="translation"><Text t={ form }/></span>

                { (word.comment && word.comment[language]) && 
                    <span className="comment">(<Text t={ word.comment }/>)</span>}

                <span className="actions">
                    { hasInfo && 
                        <a href="/#" 
                            className="expand" 
                            onClick={ onExpandClick }><IconExpand/></a> }
                </span>

            </div>

            { expanded && <WordInfo word={ word }/>}

            { ((!!pp || expanded) && examples.length > 0) && 
                <div className="word__examples">
                    { examples.map((e, inx) => (
                        <div key={ inx }>
                            <span className="armenian"><Text t={ e.armenian }/></span>
                            { e.transcription && 
                                <span className="transcription"><Text t={ e.transcription }/></span>}
                            <span className="translation"><Text t={ e }/></span>
                            { (e.comment && e.comment[language]) && 
                                <span className="comment">(<Text t={ e.comment }/>)</span> }
                        </div>
                    ))}
                </div> }
        </div>
    );
}

export default Word