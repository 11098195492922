

function Header() {

    return (
        <div id="header">
            <h1>Справочник по армянскому языку</h1>
        </div>
    )

}

export default Header