import useSwallowEventCallback from "hooks/useSwallowEventCallback";
import { useState } from "react";
import { NavLink, useResolvedPath } from "react-router-dom"

import { ReactComponent as IconBurger } from "svg/burger.svg"

function ChaptersMenu() {

    const [ active, setActive ] = useState(false);

    const toggleActive = useSwallowEventCallback(
        () => setActive(prev => !prev),
        []
    );

    const path = useResolvedPath();
    const route = path.pathname;
    
    return (
        <nav id="chapters-menu" 
            className={ active ? "active" : "" }
            onClick={ toggleActive }>
            <a href="/#" id="chapters-menu-burger">
                <IconBurger/>
            </a>
            <ul>
                <li className={ route === "/alphabet" ? "active" : null }>
                    <NavLink to="/alphabet">Алфавит</NavLink>
                </li>
                <li className={ route === "/pronouns" ? "active" : null }>
                    <NavLink to="/pronouns" className="disabled">Местоимения</NavLink>
                </li>
                <li className={ route === "/numeral" ? "active" : null }>
                    <NavLink to="/numeral" className="disabled">Числительные</NavLink>
                </li>
                <li className={ route === "/questions" ? "active" : null }>
                    <NavLink to="/questions" className="disabled">Вопросительные слова</NavLink>
                </li>
                <li className={ route === "/cases" ? "active" : null }>
                    <NavLink to="/cases">Падежи</NavLink>
                </li>
                <li className={ route === "/tenses" ? "active" : null }>
                    <NavLink to="/tenses" className="disabled">Времена глаголов</NavLink>
                </li>
                
            </ul>
        </nav>
    )

}

export default ChaptersMenu