import useFilterKey from "hooks/useFilterKey"
import useFilter from "hooks/useFilter"
import { useCallback, useMemo } from "react"

import { ReactComponent as IconClose } from "svg/close.svg"

import SearchField from "./SearchField"
import useId from "hooks/useId"
import Text from "./Text"
import useSwallowEventCallback from "hooks/useSwallowEventCallback"

function Search() {

    const filter = useFilter();
    const query = useFilterKey("query");
    const wordId = useFilterKey("word");
    const word = useId(wordId);
    const nominative = useMemo(
        () => word ? word.cases.find(c => c.case === "nominative") : null,
        [ word ]
    );

    const onWordClearClick = useSwallowEventCallback(
        () => filter.set("word", null),
        []
    );
        
    const before = useMemo(
        () => nominative ? 
            <div className="search-word">
                <Text t={ nominative.single || nominative }/>
                <a href="/#" onClick={ onWordClearClick }><IconClose/></a>
            </div> :
            null,
        [ nominative ]
    );

    const onChange = useCallback(
        (value) => filter.set("query", value),
        [ filter ]
    );

    return (
        <div className="search">
            <SearchField 
                before={ before }
                placeholder="Поиск"
                value={ query || "" }
                onChange={ onChange }/>
        </div>
    )

}

export default Search