import useSwallowEventCallback from "hooks/useSwallowEventCallback";
import { useCallback } from "react"
import { ReactComponent as IconClose } from "svg/close.svg"

function SearchField(props) {

    const { value, onChange, before, ...pass } = props;

    const handleChange = useCallback(
        (e) => {
            onChange(e.target.value);
        },
        [ onChange ]
    );

    const onClear = useSwallowEventCallback(
        () => onChange(""),
        [ onChange ]
    );

    return (
        <div className="search-field">
            { before }
            <input value={ value } onChange={ handleChange } { ...pass }/>
            { value && 
                <a href="/#" className="search-field__clear" onClick={ onClear }>
                    <IconClose/>
                </a> }
        </div>
    )
}

export default SearchField