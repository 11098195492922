
class Article {

    id = null
    title = null
    language = null
    text = null

    constructor(file) {

        this.id = file.id;
        this.language = file.language;

        const text = file.text;
        //const inx = text.indexOf("\n");
        //const title = text.substring(0, inx).substring(1);

        this.title = file.title.trim();
        this.text = file.text;
        //this.text = text.substring(inx).trim();
    }

}

export default Article