const alphabet = [
    {
        armenian: {
            lower: "ա",
            upper: "Ա"
        },
        russian: "а",
    },
    {
        armenian: {
            lower: "բ",
            upper: "Բ"
        },
        russian: "б",
    },
    {
        armenian: {
            lower: "գ",
            upper: "Գ"
        },
        russian: "г"
    },
    {
        armenian: {
            lower: "դ",
            upper: "Դ"
        },
        russian: "д"
    },
    {
        armenian: {
            lower: "ե",
            upper: "Ե"
        },
        russian: "йе/э"
    },
    {
        armenian: {
            lower: "զ",
            upper: "Զ"
        },
        russian: "з"
    },
    {
        armenian: {
            lower: "է",
            upper: "Է"
        },
        russian: "э"
    },
    {
        armenian: {
            lower: "ը",
            upper: "Ը"
        },
        russian: "э"
    },
    {
        armenian: {
            lower: "թ",
            upper: "Թ"
        },
        russian: "т'"
    },
    {
        armenian: {
            lower: "ժ",
            upper: "Ժ"
        },
        russian: "ж"
    },
    {
        armenian: {
            lower: "ի",
            upper: "Ի"
        },
        russian: "и"
    },
    {
        armenian: {
            lower: "լ",
            upper: "Լ"
        },
        russian: "л"
    },
    {
        armenian: {
            lower: "խ",
            upper: "Խ"
        },
        russian: "х'"
    },
    {
        armenian: {
            lower: "ծ",
            upper: "Ծ"
        },
        russian: "ц"
    },
    {
        armenian: {
            lower: "կ",
            upper: "Կ"
        },
        russian: "к"
    },
    {
        armenian: {
            lower: "հ",
            upper: "Հ"
        },
        russian: "х"
    },
    {
        armenian: {
            lower: "ձ",
            upper: "Ձ"
        },
        russian: "дз"
    },
    {
        armenian: {
            lower: "ղ",
            upper: "Ղ"
        },
        russian: "гх"
    },
    {
        armenian: {
            lower: "ճ",
            upper: "Ճ"
        },
        russian: "дж"
    },
    {
        armenian: {
            lower: "մ",
            upper: "Մ"
        },
        russian: "м"
    },
    {
        armenian: {
            lower: "յ",
            upper: "Յ"
        },
        russian: "й"
    },
    {
        armenian: {
            lower: "ն",
            upper: "Ն"
        },
        russian: "н"
    },
    {
        armenian: {
            lower: "շ",
            upper: "Շ"
        },
        russian: "ш"
    },
    {
        armenian: {
            lower: "ո",
            upper: "Ո"
        },
        russian: "о/во",
        russianComment: "[во] в начале слова, [о] в середине слова"
    },
    {
        armenian: {
            lower: "չ",
            upper: "Չ"
        },
        russian: "ч"
    },
    {
        armenian: {
            lower: "պ",
            upper: "Պ"
        },
        russian: "п"
    },
    {
        armenian: {
            lower: "ջ",
            upper: "Ջ"
        },
        russian: "чж"
    },
    {
        armenian: {
            lower: "ռ",
            upper: "Ռ"
        },
        russian: "р"
    },
    {
        armenian: {
            lower: "ս",
            upper: "Ս"
        },
        russian: "с"
    },
    {
        armenian: {
            lower: "վ",
            upper: "Վ"
        },
        russian: "в"
    },
    {
        armenian: {
            lower: "տ",
            upper: "Տ"
        },
        russian: "т"
    },
    {
        armenian: {
            lower: "ր",
            upper: "Ր"
        },
        russian: "р'"
    },
    {
        armenian: {
            lower: "ց",
            upper: "Ց"
        },
        russian: "ц"
    },
    {
        armenian: {
            lower: "ու",
            upper: "Ու"
        },
        russian: "у"
    },
    {
        armenian: {
            lower: "փ",
            upper: "Փ"
        },
        russian: "п'"
    },
    {
        armenian: {
            lower: "ք",
            upper: "Ք"
        },
        russian: "к'"
    },
    {
        armenian: {
            lower: "և",
            upper: ""
        },
        russian: "ев"
    },
    {
        armenian: {
            lower: "o",
            upper: "O"
        },
        russian: "о"
    },
    {
        armenian: {
            lower: "ֆ",
            upper: "Ֆ"
        },
        russian: "ф"
    }
]

export default alphabet;