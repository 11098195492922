import { ReactComponent as SvgLoading } from "svg/loading.svg"

function Loader() {

    return (
        <div className="loader">
            <SvgLoading/>
        </div>
    )
}

export default Loader