import AppContext from "context/AppContext";
import { useContext } from "react"

function process(t) {
    if (t.indexOf("*") === -1) {
        return t;
    }
    return t.split("*").map((part, inx) => {
        return inx % 2 === 1 ? 
                <b key={ inx }>{ part }</b> : 
                part;
    })
}

function Text({ t, separator = "/" }) {
    const app = useContext(AppContext);
    const language = app.get("language");

    if (!t) {
        return "";
    }

    if (typeof t === "string") {
        return process(t);
    }

    let value = t[language] || t;

    if (typeof value === "string") {
        return process(value);
    }
    else if (Array.isArray(value)) {
        return value.join(separator);
    }
}

export default Text