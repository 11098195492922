

function Footer() {

    return (
        <div className="footer">
            Составители: Иван Куинджи, Hripsime Barkhudaryan. Контакты: kuindji at gmail.com
        </div>
    )
}

export default Footer