import Observable from "@kuindji/observable"

class Context extends Observable {

    data = {}

    constructor(data) {
        super();
        Object.assign(this.data, data);
    }

    set(key, value) {

        if (typeof key === "string") {
            if (this.data[key] !== value) {
                this.data[key] = value;
                this.trigger(key, value);
                this.trigger("change");
            }
        }
        else {
            const values = key;
            let hasChanges = false;
            Object.keys(values).forEach(key => {
                const value = values[key];
                if (this.data[key] !== value) {
                    this.data[key] = value;
                    hasChanges = true;
                    this.trigger(key, value);
                }
            })
            hasChanges && this.trigger("change");
        }
    }

    get(key) {
        if (typeof key === "string") {
            return this.data[key];
        }
        else {
            const slice = {};
            key.forEach(k => {
                slice[k] = this.data[k]
            })
            return slice;
        }
    }

    getData() {
        return {...this.data};
    }

    isEmpty() {
        const keys = Object.keys(this.data);
        if (keys.length === 0) {
            return true;
        }
        return keys.map(k => this.data[k]).filter(v => v !== null).length === 0;
    }
}

export default Context