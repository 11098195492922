import { useMemo, useState } from "react"

import Word from "components/Word"
import Text from "./Text"

import { ReactComponent as IconExpand } from "svg/expand.svg"

import useUpdateEffect from "hooks/useUpdateEffect"
import useSwallowEventCallback from "hooks/useSwallowEventCallback"


function Words({ words, 
                    title = null,
                    comment = null, 
                    limit = 5,
                    Header = "h3", 
                    className = "" }) {

    const [ first, setFirst ] = useState(() => words.slice(0, limit));
    const [ hasMore, setHasMore ] = useState(() => words.length > limit);
    const [ expanded, setExpanded ] = useState(false);

    useUpdateEffect(
        () => {
            setFirst(words.slice(0, limit));
            setHasMore(words.length > limit);
        },
        [ words, limit ]
    );

    const onExpandToggle = useSwallowEventCallback(
        () => setExpanded(prev => !prev),
        []
    );

    const cls = useMemo(
        () => [ "word-list", className, expanded ? "expanded" : "" ].join(" "),
        [ className, expanded ]
    );

    if (!first || first.length === 0) {
        return null;
    }

    return (
        <div className={ cls }>
            { title && 
                <Header>
                    <Text t={ title }/>
                    { comment ? <span className="comment">(<Text t={ comment }/>)</span> : null }
                </Header> }
            { (expanded ? words : first).map(w => <Word key={ w.id } word={ w }/>)}
            { hasMore && 
                <a href="/#" 
                    className="more" 
                    onClick={ onExpandToggle }
                    children={ <IconExpand/> }/> }
        </div>
    )
}

export default Words