
import QuestionGroup from "./QuestionGroup"

function QuestionGroups({ questionGroups }) {

    if (questionGroups.length === 0) {
        return null;
    }

    return (
        <div className="full-case__questions">
            <h3>Отвечает на вопросы:</h3>
            { questionGroups.map((g, inx) => <QuestionGroup key={ g.type || inx } group={ g }/> )}
        </div>
    )
}

export default QuestionGroups;