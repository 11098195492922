import { useMemo, useState } from "react"
import { marked } from "marked"

import { ReactComponent as IconExpand } from "svg/expand.svg"
import useSwallowEventCallback from "hooks/useSwallowEventCallback";


function Article({ article }) {

    const [ expanded, setExpanded ] = useState(false);

    const toggleExpanded = useSwallowEventCallback(
        () => setExpanded(prev => !prev),
        []
    );

    const html = useMemo(
        () => marked.parse(article.text),
        [ article ]
    );

    return (
        <div className={ "article " + (expanded ? "expanded" : "") }>
            <h3>
                <a href="/#" onClick={ toggleExpanded }>
                    { article.title }
                    <IconExpand/>
                </a>
            </h3>
            { expanded && <div dangerouslySetInnerHTML={{ __html: html }}/> }
        </div>
    )

}

export default Article