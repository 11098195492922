
import Text from "components/Text"
import Words from "components/Words"
import AppContext from "context/AppContext"
import useContextKey from "hooks/useContextKey"

function Declension({ declension }) {

    const language = useContextKey("language", AppContext);

    return (
        <div className="full-case__declension">
            <h3>
                <Text t={ declension.name }/>
                { (declension.comment && declension.comment[language]) && 
                    <span className="comment">(<Text t={ declension.comment }/>)</span>}
            </h3>
            { declension.groups.map((g, inx) => <Words 
                                        key={ inx } 
                                        words={ g.words } 
                                        title={ g.name }
                                        Header="h4"/>)}
        </div>
    )
}

export default Declension